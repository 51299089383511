@import "../../styles/variables";

.notFound {
  height: 100svh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $montserrat;
  flex-direction: column;
  padding: 20px;
  text-align: center;
  h1 {
    margin-top: 20px;
    color: #4b6356;
  }
  img {
    width: 200px;
  }
  p {
    color: #808080;
  }
}
